import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import { IAppUser, NoteHeadlineEntityProperties, NoticeDefinitionEntityProperties, NoticeType, ReportReasonEntityProperties, filterComplianceUsers } from "../../Components/Main/Compliance/shared/helpers";
import QueryHelper from "../../Components/Utilities/QueryHelper";

export const fetchComplianceAnalysts = createAsyncThunk("compliance/fetchComplianceAnalysts", async (arg: { queryHelper: QueryHelper }) => {
	arg.queryHelper.pageSize = 1000;
	arg.queryHelper.criteria = [];
	arg.queryHelper.setMace();

	const res = await arg.queryHelper.fetchAllData("user");
	const filteredResults = filterComplianceUsers(res);

	return filteredResults;
});

export const fetchAors = createAsyncThunk("compliance/fetchAors", async (arg: { queryHelper: QueryHelper }) => {
	if (!store.getState().compliance?.aors?.length) {
		arg.queryHelper.setCompliance();
		arg.queryHelper.pageSize = 1000;
		arg.queryHelper.criteria = [];
		arg.queryHelper.setMembershipV3();

		const res = await arg.queryHelper.fetchAllData("aors");
		return res;
	} else {
		return store.getState().compliance.aors;
	}
});

export const fetchReportReasons = createAsyncThunk("compliance/reportReasons", async (arg: { queryHelper: QueryHelper }) => {
	if (!store.getState().compliance?.aors?.length) {
		arg.queryHelper.setCompliance();
		arg.queryHelper.pageSize = 1000;
		arg.queryHelper.criteria = [];

		const res = await arg.queryHelper.fetchAllData("reportReasons");
		return res;
	} else {
		return store.getState().compliance.reportReasons;
	}
});

let currentRequest: any = null;

export const fetchNotices = createAsyncThunk("compliance/fetchNotices", async (arg: { queryHelper: QueryHelper; caseId: string }, { rejectWithValue }) => {
	arg.queryHelper.setCompliance();
	const criteria = [
		{
			field: "complianceCaseId",
			op: 0,
			values: [arg.caseId],
		},
	];

	const orderBy = [
		{
			field: "createdOn",
			direction: "Desc",
		},
	];

	if (currentRequest) {
		currentRequest.cancel();
	}

	currentRequest = arg.queryHelper.fetchDataWithCriteriaOrderBy("NoticeDefinitions", criteria, orderBy);
	currentRequest.cancel = rejectWithValue;

	try {
		const res = await currentRequest;
		return res.results;
	} catch (err) {
		if (!err.isCancelled) {
			throw err;
		}
	} finally {
		currentRequest = null;
	}
});

export const setCase = createAsyncThunk("compliance/setCase", async (arg: { complianceCase: any }) => {
	return arg.complianceCase;
});

export const setReplyRead = createAsyncThunk("compliance/setReplyRead", async (arg: { noticeId: string; replyId: string }) => {
	return { noticeId: arg.noticeId, replyId: arg.replyId };
});
export const addNotice = createAsyncThunk("compliance/addNotice", async (arg: { notice: NoticeDefinitionEntityProperties }) => {
	return { notice: arg.notice };
});

export const addNoticeReply = createAsyncThunk("compliance/addReply", async (arg: { notice: NoticeDefinitionEntityProperties }) => {
	return { notice: arg.notice };
});
export const fetchCase = createAsyncThunk("compliance/fetchCase", async (arg: { queryHelper: QueryHelper; caseId: string }) => {
	arg.queryHelper.setCompliance();

	const res = await arg.queryHelper.fetchResource("ComplianceCases", arg.caseId, false);
	return res.results[0];
});

export const fetchCitationDispositions = createAsyncThunk("compliance/citationDispositions", async (arg: { queryHelper: QueryHelper }) => {
	if (!store.getState().compliance?.citationDispositions?.length) {
		arg.queryHelper.setCompliance();
		const res = await arg.queryHelper.fetchDataWithCriteria("EnumerationLookups", [
			{
				field: "enumerationName",
				op: 0,
				values: ["Citation"],
			},
		]);
		return res.results;
	} else {
		return store.getState().compliance.citationDispositions;
	}
});

export const fetchNoteHeaders = createAsyncThunk("compliance/noteHeaders", async (arg: { queryHelper: QueryHelper }) => {
	arg.queryHelper.setCompliance();
	arg.queryHelper.criteria = [];
	const res = await arg.queryHelper.fetchAllData("Headlines");
	return res?.filter((headline: any) => !headline.isDeleted);
});

export const fetchInvestigationDispositions = createAsyncThunk("compliance/investigationDispositions", async (arg: { queryHelper: QueryHelper }) => {
	if (!store.getState().compliance?.investigationDispositions?.length) {
		arg.queryHelper.setCompliance();
		const res = await arg.queryHelper.fetchDataWithCriteria("EnumerationLookups", [
			{
				field: "enumerationName",
				op: 0,
				values: ["Investigation"],
			},
		]);
		return res.results;
	} else {
		return store.getState().compliance.investigationDispositions;
	}
});

export const complianceSlice = createSlice({
	name: "compliance",
	initialState: {
		noteHeaders: [] as NoteHeadlineEntityProperties[],
		complianceCase: undefined as any,
		investigationDispositions: undefined as any,
		citationDispositions: undefined as any,
		notices: [] as NoticeDefinitionEntityProperties[],
		analysts: [] as IAppUser[],
		aors: [] as any[],
		reportReasons: [] as ReportReasonEntityProperties[],
		loadingNotices: false,
	},
	reducers: {
		getCase: (state, action) => {
			state.complianceCase = action.payload;
		},
		getInvestigationDispositions: (state, action) => {
			state.investigationDispositions = action.payload;
		},
		getCitationDispositions: (state, action) => {
			state.citationDispositions = action.payload;
		},
		getNotices: (state, action) => {
			state.notices = action.payload;
		},
		getComplianceAnalysts: (state, action) => {
			state.analysts = action.payload;
		},
		getAors: (state, action) => {
			state.aors = action.payload;
		},
		getNoteHeaders: (state, action) => {
			state.noteHeaders = action.payload;
		},
		getReportReasons: (state, action) => {
			state.reportReasons = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCase.fulfilled, (state, action) => {
				state.complianceCase = action.payload;
			})
			.addCase(fetchInvestigationDispositions.fulfilled, (state, action) => {
				state.investigationDispositions = action.payload;
			})
			.addCase(fetchCitationDispositions.fulfilled, (state, action) => {
				state.citationDispositions = action.payload;
			})
			.addCase(fetchNotices.fulfilled, (state, action) => {
				state.notices = action.payload;
				state.loadingNotices = false;
			})
			.addCase(fetchNotices.pending, (state) => {
				state.loadingNotices = true;
			})
			.addCase(setCase.fulfilled, (state, action) => {
				state.complianceCase = action.payload;
			})
			.addCase(fetchComplianceAnalysts.fulfilled, (state, action) => {
				state.analysts = action.payload;
			})
			.addCase(fetchAors.fulfilled, (state, action) => {
				state.aors = action.payload;
			})
			.addCase(fetchReportReasons.fulfilled, (state, action) => {
				state.reportReasons = action.payload;
			})
			.addCase(setReplyRead.fulfilled, (state, action) => {
				state.notices = [
					...state.notices.map((notice) => {
						if (notice.id === action.payload.noticeId) {
							notice.replies = notice.replies.map((reply) => {
								if (reply.id === action.payload.replyId) {
									reply.isRead = true;
								}
								return reply;
							});
						}
						return notice;
					}),
				];
			})
			.addCase(addNotice.fulfilled, (state, action) => {
				state.notices = [action.payload.notice, ...state.notices];
			})
			.addCase(addNoticeReply.fulfilled, (state, action) => {
				state.notices.find((notice) => notice.id === action.payload.notice.id)?.replies.push(action.payload.notice.replies[action.payload.notice.replies?.length - 1]);
			})
			.addCase(fetchNoteHeaders.fulfilled, (state, action) => {
				state.noteHeaders = action.payload;
			});
	},
});

export const selectInvestigationDispositionsFromStore = createSelector(
	(state: RootState) => state.compliance.investigationDispositions,
	(investigationDispositions) => {
		if (investigationDispositions) {
			return investigationDispositions;
		}
	}
);
export const selectCitationDispositions = createSelector(
	(state: RootState) => state.compliance.citationDispositions,
	(citationDispositions) => {
		if (citationDispositions) {
			return citationDispositions;
		}
	}
);

export const selectCaseFromStore = createSelector(
	(state: RootState) => state.compliance.complianceCase,
	(complianceCase) => {
		if (complianceCase) {
			return complianceCase;
		}
	}
);
export const selectNoticesFromStore = createSelector(
	(state: RootState) => state.compliance.notices,
	(notices) => {
		if (notices) {
			return notices;
		}
	}
);
export const selectCitationNoticeFromStore = createSelector(
	(state: RootState) => state.compliance.notices,
	(notices) => {
		if (notices) {
			return notices.filter((notice) => notice.noticeType === NoticeType.Citation);
		}
	}
);
export const selectWarningNoticeFromStore = createSelector(
	(state: RootState) => state.compliance.notices,
	(notices) => {
		if (notices) {
			return notices.filter((notice) => notice.noticeType === NoticeType.Warning);
		}
	}
);
export const selectAnalystsFromStore = createSelector(
	(state: RootState) => state.compliance.analysts,
	(analysts) => {
		if (analysts) {
			return analysts.slice().sort((a: IAppUser, b: IAppUser) => a.firstName.localeCompare(b.firstName));
		}
	}
);
export const selectAorsFromStore = createSelector(
	(state: RootState) => state.compliance.aors,
	(aors) => {
		if (aors) {
			return aors;
		}
	}
);

export const selectReportReasonsFromStore = createSelector(
	(state: RootState) => state.compliance.reportReasons,
	(reportReasons) => {
		if (reportReasons) {
			return reportReasons;
		}
	}
);

export const selectNoticesLoadingFromStore = createSelector(
	(state: RootState) => state.compliance.loadingNotices,
	(loadingNotices) => {
		return loadingNotices;
	}
);

export const selectRuleWikiAnalysts = createSelector(
	(state: RootState) => state.compliance.analysts,
	(analysts) => {
		if (analysts) {
			return analysts.map((user) => {
				return { id: user.id, display: user.displayName };
			});
		}
	}
);

export const selectNoteHeadlinesFromStore = createSelector(
	(state: RootState) => state.compliance.noteHeaders,
	(noteHeaders) => {
		if (noteHeaders) {
			return noteHeaders;
		}
	}
);
// Action creators are generated for each case reducer function
export const { getCase, getInvestigationDispositions, getNotices, getComplianceAnalysts, getAors } = complianceSlice.actions;

const { reducer } = complianceSlice;
export default reducer;
