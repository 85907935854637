import dayjs from "dayjs";

// TODO: replace this with service at some point
export let CampaignStatuses = ['Draft','Pending','Approved','Sending','Complete'];

// TODO: replace this with service at some point
export let CampaignPriorities = [
    {
        name: 'Low',
        priority: 15
    },
    {
        name: 'Normal',
        priority: 50
    },
    {
        name: 'High',
        priority: 75
    },
    {
        name: 'Critical',
        priority: 90
    },
];

export const getRange = (startDate, endDate, type, keys) => {
    // build the return shape
    let shape = {}
    keys.forEach(key => shape[key] = 0);

    let interval = 'day';
    switch (type) {
        case 'month':
            interval = 'day';
            break;
        case 'year':
            interval = 'month';
            break;
        default:
            interval = 'day'
            break;
    }

    let fromDate = dayjs(startDate).startOf('day');
    let toDate = dayjs(endDate);
    let diff = toDate.diff(fromDate, interval) + 1;
    let range = [];
    for (let i = 0; i < diff; i++) {
        const cloned_shape = {...shape}
        cloned_shape.date = dayjs(startDate).add(i, interval).startOf('day').toDate();
        range.push(cloned_shape);
    }

    return range;
}

export const getRawRange = (startDate, endDate, keys, interval) => {
    // build the return shape
    let shape = {}
    keys.forEach(key => shape[key] = 0);

    let fromDate = dayjs(startDate).startOf('day');
    let toDate = dayjs(endDate);
    let diff = toDate.diff(fromDate, interval);
    let range_array = [];
    for (let i = 0; i < diff; i++) {
        const cloned_shape = {...shape}
        cloned_shape.date = dayjs(startDate).add(i, interval).startOf(interval).toDate();
        range_array.push(cloned_shape);
    }

    // cut off ther first day because math is hard
    range_array.shift();
    console.log(range_array);
    return range_array;
}

export const getPalette = () => {
    return [
        '#e5effd', // processed
        '#e5fcd0', // sent
        '#dfc14a', // prior bounce
        '#e3423d', // prior unsub
        '#94df4a', // delivered
        '#828897', // blocked
        '#f3d768', // bounce 
        '#f4daff', // dropped
        '#86d4f5', // open
        '#69c1e6', // click
        '#f77dc2', // spam report
        '#fa625e' // unsubscribe
    ];
}

export function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth();
}

export const mlses = [
    'CDAR', 'CR Paragon', 'CRF', 'GSMLS', 'ITECH', 'Matrix', 'PFAR', 'SPN Paragon', 'VCRDS'
];

export const getTrainingRequestStatuses = () => {
    return [
        {
            value: 1,
            label: 'New',
        },
        {
            value: 2,
            label: 'Approved',
        },
        {
            value: 3,
            label: 'Scheduled',
        },
        {
            value: 4,
            label: 'Cancelled',
        },
    ];
}

export const getTrainingRequestStatusById = (id) => {
    switch (id) {
        case 2:
            return 'ACTIVE';
        case 3:
            return 'SCHEDULED';
        case 4:
            return 'COMPLETED';
        case 5:
            return 'CANCELLED';
        case 1:
        default:
            return 'NEW';
    }
}

export function isGUID (input) {
	const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	return regex.test(input);
}
